import TopNavBar from "./TopNavBar";
import ResponsiveEmbed from 'react-responsive-embed';

const HomePage = () => {
  return (
    <div style={{ backgroundColor: '#BBEB02', padding: '20px' }}>
      <TopNavBar />
      <h1>Welcome to ELELEM AI</h1>
      <p>Are you an GenAI application developer? Are you looking to power your RAG-based LLM application with relevant contents from popular publishers LEGALLY?</p>
      <p>With the rise of GenAI applications trying to clone and train their LLM on websites, Publishers are stepping up measures to prevent unauthorized crawling and scraping of their websites to protect their contents. ELELEM AI solves the problem by providing a virtual API for publishers where they can monetize their contents and allow application developers like you to access it legally.</p>
      <p>Sign up for ELELEM AI's virtual API token to join the waitlist. With this API token, you can get access to popular publisher contents via our API and start building powerful GenAI applications.</p>
      <ResponsiveEmbed
        src="https://docs.google.com/forms/d/e/1FAIpQLSeDtSYxuFhTqraBnJU52rO1wD_UEdW7kUxamZ56Snax7GKphg/viewform?embedded=true"
      />
    </div>    
  );
}

export default HomePage;
