import "./TopNavBar.css";

const TopNavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="https://elelem.ai/">
                    <img src="/logo.png" alt="ELELEM Logo" style={{ maxHeight: "60px" }} />
                </a>
            </div>
        </nav>
    );
}

export default TopNavBar;

